
<script setup>
import { ref } from 'vue'

const title = ref('About')

</script>


<template>
    <h2>{{ title }}</h2>
</template>


